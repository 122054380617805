<template>
  <div class="wrap" v-loading="loading" id="wrap">
    <div class="btnBox marB10" id="search-card">
      <el-button type="primary" size="mini" @click="saveAllData"
        >保存</el-button
      >
      <el-button icon="el-icon-refresh-right" size="mini" @click="refresh"
        >刷新</el-button
      >
    </div>
    <div class="x-x">
      <el-card class="leftTree">
        <div class="leftTreeShop">
          <div class="treeShop x-x">
            <el-scrollbar
              :style="{
                height: `${tableHeight + 35}px`,
                borderRight: '1px solid #dfe6ec',
                width: '50%',
                padding: '10px 0 0 10px'
              }"
            >
              <div>
                <div class="left-title marB10">
                  <span>门店</span>
                </div>
                <div class="y-start">
                  <el-input
                    class="searchTree"
                    v-model="shopName"
                    placeholder="请输入门店名称"
                    clearable
                    size="mini"
                    prefix-icon="el-icon-search"
                    style="margin-bottom: 20px"
                  />
                </div>
                <div class="">
                  <el-tree
                    node-key="id"
                    :data="shopTreeOptions"
                    :filter-node-method="filterNodeShop"
                    ref="shopTree"
                    :default-expand-all="isShopExpand"
                    show-checkbox
                    check-on-click-node
                    @check="checkBoxFn('shopTree')"
                  />
                </div>
              </div>
            </el-scrollbar>
            <el-scrollbar
              :style="{
                height: `${tableHeight + 35}px`,
                width: '50%',
                padding: '10px 0 0 10px'
              }"
            >
              <div>
                <div class="left-title marB10">
                  <span>商品类别</span>
                </div>
                <div class="y-start">
                  <el-input
                    class="searchTree"
                    v-model="goodsCategoryName"
                    placeholder="请输入商品类别名称"
                    clearable
                    size="mini"
                    prefix-icon="el-icon-search"
                    style="margin-bottom: 20px"
                  />
                </div>
                <!--        <div class="">-->
                <el-tree
                  node-key="id"
                  :data="goodsCategoryTreeOptions"
                  :filter-node-method="filterNodeGoodsCategory"
                  ref="categoryTree"
                  :default-expand-all="isGoodsCategoryExpand"
                  show-checkbox
                  check-on-click-node
                  @check="checkBoxFn('categoryTree')"
                />
                <!--        </div>-->
              </div>
            </el-scrollbar>
          </div>
        </div>
      </el-card>
      <cardTitleCom
        cardTitle="商品信息"
        tooltipText="只有进场的商品才能上架"
        class="rightBox"
      >
        <template #rightCardTitle>
          <span class="fontColorF00 fontS12">
            此处数据量较大，预计修改成功5分钟后生效。
          </span>
          <selectAllFilter v-model="queryParams" :filters="filters" />
          <el-button
            class="marL10 marR10"
            type="primary"
            size="mini"
            @click="getList"
            >查询</el-button
          >
        </template>
        <template slot="cardContent">
          <div class="tableContent">
            <el-table
              :data="tableData"
              :max-height="tableHeight"
              :height="tableHeight"
              border
              ref="multipleTable"
            >
              <el-table-column
                label="序号"
                width="80"
                align="center"
                type="index"
              />
              <el-table-column
                label="商品编码"
                align="center"
                key="goodsNo"
                prop="goodsNo"
                :show-overflow-tooltip="true"
                sortable
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                label="商品名称"
                align="center"
                prop="goodsName"
                :show-overflow-tooltip="true"
                min-width="160"
              />
              <el-table-column
                label="商品条码"
                align="center"
                prop="barcode"
                :show-overflow-tooltip="true"
                min-width="160"
              />
              <el-table-column
                label="单位"
                align="center"
                prop="unitName"
                :show-overflow-tooltip="true"
                min-width="160"
              />
              <el-table-column
                label="规格"
                align="center"
                prop="goodsSpec"
                :show-overflow-tooltip="true"
                min-width="160"
              />
              <el-table-column label="行全选操作" align="center" width="200">
                <el-table-column align="center" width="100">
                  <template slot="header">
                    <div class="x-fc">
                      <span class="marR10">门店进场</span>
                      <el-checkbox
                        :indeterminate="isIndeterminateIsCanUseColumn"
                        @change="changeIsCanUseColumn"
                        :disabled="tableData.length == 0"
                        v-model="isCanUseColumn"
                      />
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <el-checkbox
                      :indeterminate="scope.row.isIndeterminateIsCanUse"
                      @change="
                        changeIsCanUseLine(scope.row.isCanUse, scope.row)
                      "
                      v-model="scope.row.isCanUse"
                      >进场</el-checkbox
                    >
                  </template>
                </el-table-column>

                <el-table-column align="center" width="100">
                  <template slot="header">
                    <div class="x-fc">
                      <span class="marR10">零售上架</span>
                      <el-checkbox
                        :indeterminate="isIndeterminateIsPosVisibleColumn"
                        @change="changeIsPosVisibleColumn"
                        :disabled="
                          !isCanUseColumn ||
                          isIndeterminateIsCanUseColumn ||
                          tableData.length == 0
                        "
                        v-model="isPosVisibleColumn"
                      />
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <el-checkbox
                      :disabled="
                        !scope.row.isCanUse || !scope.row.isPosVisibleRearEnd
                      "
                      :indeterminate="scope.row.isIndeterminateIsPosVisible"
                      @change="
                        changeIsPosVisibleLine(
                          scope.row.isPosVisible,
                          scope.row
                        )
                      "
                      v-model="scope.row.isPosVisible"
                      >上架</el-checkbox
                    >
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column
                :label="item.label"
                align="center"
                width="300"
                v-for="(item, index) in shops"
                :key="`${item.id}${item.isCanUse}${item.isPosVisible}${item.isIndeterminateIsCanUse}${item.isIndeterminateIsPosVisible}`"
              >
                <el-table-column align="center" width="100">
                  <template slot="header">
                    <div class="x-fc">
                      <span class="marR10">门店进场</span>
                      <el-checkbox
                        :indeterminate="item.isIndeterminateIsCanUse"
                        @change="changeIsCanUseSelect(item.id, index)"
                        v-model="item.isCanUse"
                      />
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <el-checkbox
                      v-model="scope.row.shopGoodsReps[index].isCanUse"
                      @change="
                        changeIsCanUseItem(
                          index,
                          scope.row,
                          scope.row.shopGoodsReps[index].isCanUse
                        )
                      "
                    />
                  </template>
                </el-table-column>
                <el-table-column align="center" width="100">
                  <template slot="header">
                    <div class="x-fc">
                      <span class="marR10">零售上架</span>
                      <el-checkbox
                        :indeterminate="item.isIndeterminateIsPosVisible"
                        @change="changeIsPosVisibleSelect(item.id, index)"
                        v-model="item.isPosVisible"
                      />
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <el-checkbox
                      :disabled="
                        !scope.row.shopGoodsReps[index].isCanUse ||
                        !scope.row.isPosVisibleRearEnd
                      "
                      v-model="scope.row.shopGoodsReps[index].isPosVisible"
                      @change="changeIsPosVisibleItem(index, scope.row)"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  label="门店售价"
                  align="center"
                  width="100"
                  prop="salePrice"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.shopGoodsReps[index].shopPrice }}</span>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
            <div>
              <bottomPage
                v-model="queryParams"
                :getList="getList"
                :isSelectAll="false"
                :totalCom="total"
              />
              <!-- <pagination
                v-show="total > 0"
                :total="total"
                :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize"
                @pagination="getList"
              /> -->
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { getDeptTree } from '@/api/shop/base/shopInfo'
import { getShopAndGoodsData, saveOrUpdate } from '@/api/shop/base/shopGoods'
import { listCategoryTree } from '@/api/goods/category'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import selectAllFilter from '@/components/tablePage/select/select-all-filter/index.vue' //搜索
import bottomPage from '@/views/components/bottomPage' //底部分页
export default {
  name: 'ShopBaseGoods',
  components: { Treeselect, cardTitleCom, selectAllFilter, bottomPage },
  data () {
    return {
      //门店进场半选
      isIndeterminateIsCanUseColumn: false,
      //门店上架半选
      isIndeterminateIsPosVisibleColumn: false,
      //门店进场全选
      isCanUseColumn: false,
      //门店上架全选
      isPosVisibleColumn: false,
      //改变商品id
      goodsChangeIdList: [],
      //缓存商品用来比较是否更新
      cacheList: [],
      filters: [
        { filter: 'query', label: '全部' },
        { filter: 'goodsNos', label: '商品编码' },
        { filter: 'goodsNames', label: '商品名称' },
        { filter: 'barcodes', label: '商品条码' }
      ],
      isOpenTree: true, //一键展开
      //全选
      checked: false,
      //门店tree一键展开折叠
      isShopExpand: true,
      //商品tree一键展开折叠
      isGoodsCategoryExpand: true,
      // 遮罩层
      loading: false,
      //门店树过滤的名字
      shopName: undefined,
      //商品树过滤的名字
      goodsCategoryName: undefined,
      // 表格选中数组
      ids: [],
      //表格选中数组的名称
      name: [],
      // 总条数
      total: 0,
      //门店tree数据
      shopTreeOptions: [],
      //商品tree数据
      goodsCategoryTreeOptions: [],
      shops: [],
      //门店商品表格数据
      tableData: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        shopIds: [],
        categoryIds: []
      },
      isCheckChange: false,
      timer: 0,
      vivwH: 0, //页面高度
      searchH: 0 //搜索框高度
    }
  },
  watch: {
    // 根据名称筛选部门树
    shopName (val) {
      this.$refs.shopTree.filter(val)
    },
    // 根据名称筛选部门树
    goodsCategoryName (val) {
      this.$refs.categoryTree.filter(val)
    }
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.searchH - 78 - 40
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap')?.clientHeight
      this.searchH = document.getElementById('search-card')?.clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  created () {
    this.getDeptTreeData()
    this.listCategoryTreeData()
    // this.getList()
    // this.isCompleteAllCheck()
  },
  methods: {
    //全体进场
    changeIsCanUseColumn () {
      //关闭半选状态
      this.isIndeterminateIsCanUseColumn = false
      if (!this.isCanUseColumn) {
        this.isPosVisibleColumn = false
        this.isIndeterminateIsPosVisibleColumn = false
      }
      //初始化缓存数组
      this.goodsChangeIdList = []
      const tableData = this.tableData.map(item => {
        //把改变后的商品id添加到改变数组
        this.goodsChangeIdList.push({
          goodsId: item.goodsId,
          unitId: item.unitId
        })
        //关闭行内半选状态
        item.isIndeterminateIsCanUse = false
        //全体上架赋值行内上架
        item.isCanUse = this.isCanUseColumn
        //全体进场关闭时,上架也会关闭
        if (!this.isCanUseColumn) {
          item.isPosVisible = false
          item.isIndeterminateIsPosVisible = false
        }
        //全体上架赋值表格内门店的上架
        item.shopGoodsReps.forEach(v => {
          //进场
          v.isCanUse = this.isCanUseColumn
          //进场关闭时,上架也会关闭
          if (!this.isCanUseColumn) {
            v.isPosVisible = false
          }
        })
        return item
      })
      const shops = this.shops.map(item => {
        //关闭表头进场半选状态
        item.isIndeterminateIsCanUse = false
        //全体进场赋值表头进场
        item.isCanUse = this.isCanUseColumn
        //进场关闭时,上架也会关闭
        if (!this.isCanUseColumn) {
          item.isPosVisible = false
          item.isIndeterminateIsPosVisible = false
        }
        return item
      })
      this.tableData = tableData
      this.shops = shops
    },
    //全体上架
    changeIsPosVisibleColumn () {
      //关闭半选状态
      this.isIndeterminateIsPosVisibleColumn = false
      //初始化缓存数组
      this.goodsChangeIdList = []
      const tableData = this.tableData.map(item => {
        //把改变后的商品id添加到改变数组
        this.goodsChangeIdList.push({
          goodsId: item.goodsId,
          unitId: item.unitId
        })
        //关闭行内上架半选状态
        item.isIndeterminateIsPosVisible = false
        //全体上架赋值行内上架
        //进场的才赋值
        if (item.isCanUse && item.isPosVisibleRearEnd) {
          item.isPosVisible = this.isPosVisibleColumn
        }
        //全体上架赋值表格内门店的上架
        if (item.isPosVisibleRearEnd) {
          item.shopGoodsReps.forEach(v => {
            //进场的才赋值
            if (v.isCanUse) {
              v.isPosVisible = this.isPosVisibleColumn
            }
          })
        }

        return item
      })
      const shops = this.shops.map(item => {
        //关闭表头上架半选状态
        item.isIndeterminateIsPosVisible = false
        //全体上架赋值表头上架
        //进场的才赋值
        if (item.isCanUse) {
          item.isPosVisible = this.isPosVisibleColumn
        }
        return item
      })
      this.tableData = tableData
      this.shops = shops
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap')?.clientHeight
      this.searchH = document.getElementById('search-card')?.clientHeight
    },
    //门店进场每一项
    changeIsCanUseItem (index, row, isCanUseItem) {
      //如果每一项的进场关闭，则取消上架
      if (!isCanUseItem) {
        //关闭行内的上架状态
        this.$set(row, 'isPosVisible', false)
        //关闭同门店下的上架状态
        this.$set(row.shopGoodsReps[index], 'isPosVisible', false)
        //行内的  上架  半选状态
        const isPosVisibleSeltSomeLine = row.shopGoodsReps.some(
          v => v.isPosVisible
        )
        //行内是否半选  上架
        this.$set(row, 'isIndeterminateIsPosVisible', isPosVisibleSeltSomeLine)
        //表头上架状态
        const isPosVisibleSeltSome = this.tableData.some(
          v => v.shopGoodsReps[index].isPosVisible
        )
        //表头是否半选  上架
        this.shops[index].isIndeterminateIsPosVisible = isPosVisibleSeltSome
      }
      //表头进场状态
      const isCanUseSeltEvery = this.tableData.every(
        v => v.shopGoodsReps[index].isCanUse
      )
      //表头是否勾选   进场
      this.shops[index].isCanUse = isCanUseSeltEvery
      const isCanUseSeltSome = this.tableData.some(
        v => v.shopGoodsReps[index].isCanUse
      )
      //表头进场半选
      this.shops[index].isIndeterminateIsCanUse =
        !isCanUseSeltEvery && isCanUseSeltSome

      //行内上架状态
      const isCanUseSeltEveryLine = row.shopGoodsReps.every(v => v.isCanUse)
      const isCanUseSeltSomeLine = row.shopGoodsReps.some(v => v.isCanUse)
      //行内是否勾选   上架
      this.$set(row, 'isCanUse', isCanUseSeltEveryLine)
      //行内是否半选  上架
      this.$set(
        row,
        'isIndeterminateIsCanUse',
        !isCanUseSeltEveryLine && isCanUseSeltSomeLine
      )
      //全场
      this.fullCourtFunction(this.tableData, true, false)
      //把改变后的商品id添加到改变数组
      if (this.goodsChangeIdList.length > 0) {
        const isDuplicate = this.goodsChangeIdList?.some(
          obj => obj?.goodsId === row?.goodsId && obj?.unitId === row?.unitId
        )
        if (!isDuplicate) {
          this.goodsChangeIdList.push(row)
        }
      } else if (row) {
        this.goodsChangeIdList.push({
          goodsId: row.goodsId,
          unitId: row.unitId
        })
      }
    },
    //门店上架每一项
    changeIsPosVisibleItem (index, row) {
      //表头上架状态
      const isPosVisibleSeltEvery = this.tableData.every(
        v => v.shopGoodsReps[index].isPosVisible
      )
      //判断是否有一项上架
      const isPosVisibleSeltSome = this.tableData.some(
        v => v.shopGoodsReps[index].isPosVisible
      )
      //表头是否勾选   上架
      this.shops[index].isPosVisible = isPosVisibleSeltEvery
      //表头是否半选  上架
      this.shops[index].isIndeterminateIsPosVisible =
        !isPosVisibleSeltEvery && isPosVisibleSeltSome

      //行内上架状态
      const isPosVisibleSeltEveryLine = row.shopGoodsReps.every(
        v => v.isPosVisible
      )
      const isPosVisibleSeltSomeLine = row.shopGoodsReps.some(
        v => v.isPosVisible
      )
      //行内是否勾选   上架
      this.$set(row, 'isPosVisible', isPosVisibleSeltEveryLine)
      //行内是否半选  上架
      this.$set(
        row,
        'isIndeterminateIsPosVisible',
        !isPosVisibleSeltEveryLine && isPosVisibleSeltSomeLine
      )
      //全场
      this.fullCourtFunction(this.tableData, false, true)
      //把改变后的商品id添加到改变数组
      if (this.goodsChangeIdList.length > 0) {
        const isDuplicate = this.goodsChangeIdList?.some(
          obj => obj?.goodsId === row?.goodsId && obj?.unitId === row?.unitId
        )
        if (!isDuplicate) {
          this.goodsChangeIdList.push(row)
        }
      } else if (row) {
        this.goodsChangeIdList.push({
          goodsId: row.goodsId,
          unitId: row.unitId
        })
      }
    },
    //门店进场
    changeIsCanUseSelect (shopId, index) {
      this.shops[index].isIndeterminateIsCanUse = false
      //如果是头部未进场则关闭上架  关闭上架半选
      if (!this.shops[index].isCanUse) {
        this.shops[index].isIndeterminateIsCanUse = false
        this.shops[index].isPosVisible = false

        this.shops[index].isIndeterminateIsPosVisible = false
      }
      //初始化缓存数组
      this.goodsChangeIdList = []
      this.tableData = this.tableData.map(item => {
        //把改变后的商品id添加到改变数组
        this.goodsChangeIdList.push({
          goodsId: item.goodsId,
          unitId: item.unitId
        })
        //除未进场以外的上架全部勾选或者取消
        item.shopGoodsReps.forEach(v => {
          if (v.shopId == shopId) {
            v.isCanUse = this.shops[index].isCanUse
            //如果是未进场则关闭上架
            if (!this.shops[index].isCanUse) {
              v.isPosVisible = false
            }
          }
        })
        //行内进场状态
        const isCanUseSeltEveryLine = item.shopGoodsReps.every(v => v.isCanUse)
        const isCanUseSeltSomeLine = item.shopGoodsReps.some(v => v.isCanUse)
        //行内是否勾选   进场
        item.isCanUse = isCanUseSeltEveryLine
        //行内是否半选  进场
        item.isIndeterminateIsCanUse =
          !isCanUseSeltEveryLine && isCanUseSeltSomeLine
        if (!this.shops[index].isCanUse) {
          //行内上架状态
          const isPosVisibleSeltEveryLine = item.shopGoodsReps.every(
            v => v.isPosVisible
          )
          const isPosVisibleSeltSomeLine = item.shopGoodsReps.some(
            v => v.isPosVisible
          )
          //行内是否勾选   进场
          item.isPosVisible = isPosVisibleSeltEveryLine
          //行内是否半选  上架
          item.isIndeterminateIsPosVisible =
            !isPosVisibleSeltEveryLine && isPosVisibleSeltSomeLine
        }

        return item
      })
      //全场
      this.fullCourtFunction(this.tableData, true, !this.shops[index].isCanUse)
    },
    //门店上架
    changeIsPosVisibleSelect (shopId, index) {
      this.shops[index].isIndeterminateIsPosVisible = false
      //初始化缓存数组
      this.goodsChangeIdList = []
      this.tableData = this.tableData.map(item => {
        //把改变后的商品id添加到改变数组
        this.goodsChangeIdList.push({
          goodsId: item.goodsId,
          unitId: item.unitId
        })
        //除未进场以外的上架全部勾选或者取消
        item.shopGoodsReps.forEach(v => {
          if (v.shopId == shopId && v.isCanUse && item.isPosVisibleRearEnd) {
            v.isPosVisible = this.shops[index].isPosVisible
          }
        })
        //行内上架状态
        const isPosVisibleSeltEveryLine = item.shopGoodsReps.every(
          v => v.isPosVisible
        )
        const isPosVisibleSeltSomeLine = item.shopGoodsReps.some(
          v => v.isPosVisible
        )
        //行内是否勾选   上架
        item.isPosVisible = isPosVisibleSeltEveryLine
        //行内是否半选  上架
        item.isIndeterminateIsPosVisible =
          !isPosVisibleSeltEveryLine && isPosVisibleSeltSomeLine
        return item
      })
      //全场
      this.fullCourtFunction(this.tableData, false, true)
    },
    //行门店进场
    changeIsCanUseLine (isCanUse, row) {
      //关闭行进场半选状态
      this.$set(row, 'isIndeterminateIsCanUse', false)
      const shopGoodsReps = row.shopGoodsReps.map(item => {
        //如果进场状态赋值给每个门店
        item.isCanUse = isCanUse
        //如果进场是false关闭所有的上架状态
        if (!isCanUse) {
          item.isPosVisible = false
        }
        return item
      })
      this.$set(row, 'shopGoodsReps', shopGoodsReps)
      //表头进场状态
      this.shops = this.headShopsFunction(this.shops, this.tableData)
      //全场
      this.fullCourtFunction(this.tableData, true, false)
      //把改变后的商品id添加到改变数组
      if (this.goodsChangeIdList.length > 0) {
        const isDuplicate = this.goodsChangeIdList?.some(
          obj => obj?.goodsId === row?.goodsId && obj?.unitId === row?.unitId
        )
        if (!isDuplicate) {
          this.goodsChangeIdList.push(row)
        }
      } else {
        this.goodsChangeIdList.push({
          goodsId: row.goodsId,
          unitId: row.unitId
        })
      }
    },
    //行零售上架
    changeIsPosVisibleLine (isPosVisible, row) {
      //把改变后的商品id添加到改变数组
      if (this.goodsChangeIdList.length > 0) {
        const isDuplicate = this.goodsChangeIdList?.some(
          obj => obj?.goodsId === row?.goodsId && obj?.unitId === row?.unitId
        )
        if (!isDuplicate) {
          this.goodsChangeIdList.push(row)
        }
      } else {
        this.goodsChangeIdList.push({
          goodsId: row.goodsId,
          unitId: row.unitId
        })
      }
      //关闭行上架半选状态
      this.$set(row, 'isIndeterminateIsPosVisible', false)
      const shopGoodsReps = row.shopGoodsReps.map(item => {
        //如果进场开启状态就把当前的行上架状态赋值给每个门店
        if (item.isCanUse) {
          item.isPosVisible = isPosVisible
        }
        return item
      })
      this.$set(row, 'shopGoodsReps', shopGoodsReps)
      //表头上架状态
      this.shops = this.shops.map((item, index) => {
        //表头上架状态
        const isPosVisibleSeltEvery = this.tableData.every(
          v => v.shopGoodsReps[index].isPosVisible
        )
        //表头是否勾选   上架
        item.isPosVisible = isPosVisibleSeltEvery
        //判断是否有一项上架
        const isPosVisibleSeltSome = this.tableData.some(
          v => v.shopGoodsReps[index].isPosVisible
        )
        //表头是否半选  上架
        item.isIndeterminateIsPosVisible =
          !isPosVisibleSeltEvery && isPosVisibleSeltSome
        return item
      })
      //全场
      this.fullCourtFunction(this.tableData, false, true)
    },
    //获取所有勾选的门店
    checkBoxFn (value) {
      if (value == 'shopTree') {
        let shopTreeList = this.$refs.shopTree.getCheckedNodes()
        this.queryParams.shopIds = shopTreeList.map(v => v.id)
      } else if (value == 'categoryTree') {
        let categoryTreeList = this.$refs.categoryTree.getCheckedNodes()
        this.queryParams.categoryIds = categoryTreeList.map(v => v.id)
      }
      this.queryParams = {
        ...this.queryParams,
        pageNum: 1,
        pageSize: 15
      }
      this.getList()
    },
    /** 查询商品列表 */
    getList () {
      this.loading = true
      getShopAndGoodsData(this.queryParams)
        .then(response => {
          //商品信息
          let tableData = response.rows || []
          let shops = []
          if (tableData?.length > 0) {
            tableData[0]?.shopGoodsReps.forEach(item => {
              shops.push({
                isCanUse: false,
                isPosVisible: false,
                isIndeterminateIsCanUse: false,
                isIndeterminateIsPosVisible: false,
                id: item.shopId,
                label: item.shopName
              })
            })
          }
          tableData = tableData.map(item => {
            //后端行内是否可以勾选   上架
            item.isPosVisibleRearEnd = JSON.parse(
              JSON.stringify(item.isPosVisible)
            )
            //行内进场状态
            const isCanUseSeltEveryLine = item.shopGoodsReps.every(
              v => v.isCanUse
            )
            const isCanUseSeltSomeLine = item.shopGoodsReps.some(
              v => v.isCanUse
            )
            //行内是否勾选   进场
            item.isCanUse = isCanUseSeltEveryLine
            //行内是否半选  进场
            item.isIndeterminateIsCanUse =
              !isCanUseSeltEveryLine && isCanUseSeltSomeLine
            /* ========= */
            //行内上架状态
            const isPosVisibleSeltEveryLine = item.shopGoodsReps.every(
              v => v.isPosVisible
            )
            const isPosVisibleSeltSomeLine = item.shopGoodsReps.some(
              v => v.isPosVisible
            )
            //行内是否勾选   上架
            item.isPosVisible = isPosVisibleSeltEveryLine
            //行内是否半选  上架
            item.isIndeterminateIsPosVisible =
              !isPosVisibleSeltEveryLine && isPosVisibleSeltSomeLine

            return item
          })
          this.shops = this.headShopsFunction(shops, tableData)
          // this.shops = JSON.parse(JSON.stringify(shops))
          this.tableData = JSON.parse(JSON.stringify(tableData))
          console.log('tableData', this.tableData)
          this.total = response.total
          this.fullCourtFunction(tableData)
          //ids
          this.goodsChangeIdList = []
          //缓存
          this.cacheList = JSON.parse(JSON.stringify(this.tableData))
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    //头部公共
    headShopsFunction (shops, tableData) {
      return shops.map((item, index) => {
        //表头进场状态
        const isCanUseSeltEvery = tableData.every(
          v => v.shopGoodsReps[index].isCanUse
        )
        //判断是否有一项进场
        const isCanUseSeltSome = tableData.some(
          v => v.shopGoodsReps[index].isCanUse
        )
        //表头是否勾选   进场
        item.isCanUse = isCanUseSeltEvery
        //表头是否半选  进场
        item.isIndeterminateIsCanUse = !isCanUseSeltEvery && isCanUseSeltSome
        /* ========== */
        //表头上架状态
        const isPosVisibleSeltEvery = tableData.every(
          v => v.shopGoodsReps[index].isPosVisible
        )
        //判断是否有一项上架
        const isPosVisibleSeltSome = tableData.some(
          v => v.shopGoodsReps[index].isPosVisible
        )
        //表头是否勾选   上架
        item.isPosVisible = isPosVisibleSeltEvery
        //表头是否半选  上架
        item.isIndeterminateIsPosVisible =
          !isPosVisibleSeltEvery && isPosVisibleSeltSome
        return item
      })
    },
    //半选
    fullCourtFunction (tableData, isCanUse = true, isPosVisible = true) {
      if (isCanUse) {
        //全场进行状态
        const isCanUseSeltEveryAll = tableData.every(item => item.isCanUse)
        const isCanUseSeltSomeAll = tableData.some(
          item => item.isCanUse || item.isIndeterminateIsCanUse
        )
        //进场
        this.isCanUseColumn = isCanUseSeltEveryAll
        //半选  进场
        this.isIndeterminateIsCanUseColumn =
          !isCanUseSeltEveryAll && isCanUseSeltSomeAll
      }
      if (isPosVisible) {
        const isPosVisibleSeltEveryAll = tableData.every(
          item => item.isPosVisible
        )
        const isPosVisibleSeltSomeAll = tableData.some(
          item => item.isPosVisible || item.isIndeterminateIsPosVisible
        )
        //上架
        this.isPosVisibleColumn = isPosVisibleSeltEveryAll
        //半选  上架
        this.isIndeterminateIsPosVisibleColumn =
          !isPosVisibleSeltEveryAll && isPosVisibleSeltSomeAll
      }
    },
    //一键展开
    clickOpenTree () {
      this.isOpenTree = !this.isOpenTree
      this.isExpand = !this.isExpand
      this.buildData()
    },
    //刷新
    refresh () {
      this.getList()
    },
    //保存
    saveAllData () {
      //过滤出改变的缓存数组
      const filteredCacheList = this.cacheList.filter(obj =>
        this.goodsChangeIdList?.some(
          v => v?.goodsId === obj?.goodsId && v?.unitId === obj?.unitId
        )
      )
      //过滤出缓存数组
      const filteredTableData = this.tableData.filter(obj =>
        this.goodsChangeIdList?.some(
          v => v?.goodsId === obj?.goodsId && v?.unitId === obj?.unitId
        )
      )
      const filteredArray = filteredTableData.filter(objA => {
        const objB = filteredCacheList.find(obj => obj.goodsId === objA.goodsId)
        if (objB) {
          const keys = Object.keys(objA)
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            if (key === 'shopGoodsReps') {
              if (!this.compareShopGoodsReps(objA[key], objB[key])) {
                return true
              }
            } else if (objA[key] !== objB[key]) {
              return true
            }
          }
          return false
        }
        return true
      })
      // console.log('goodsChangeIdList缓存id', this.goodsChangeIdList)
      // console.log('filteredCacheList过滤缓存', filteredCacheList)
      // console.log('filteredTableData', filteredTableData)

      if (filteredArray?.length > 0) {
        saveOrUpdate(filteredArray).then(response => {
          this.getList()
          this.$modal.msgSuccess('保存成功')
          // this.getList()
        })
      } else {
        this.$modal.msgSuccess('保存成功')
      }
    },
    //对比变化
    compareShopGoodsReps (arrA, arrB) {
      if (arrA.length !== arrB.length) {
        return false
      }
      for (let i = 0; i < arrA.length; i++) {
        const objA = arrA[i]
        const objB = arrB.find(obj => obj.shopId === objA.shopId)
        if (!objB) {
          return false
        }
        const keys = Object.keys(objA)
        for (let j = 0; j < keys.length; j++) {
          const key = keys[j]
          if (objA[key] !== objB[key]) {
            return false
          }
        }
      }
      return true
    },
    /** 查询商品类别 */
    listCategoryTreeData () {
      listCategoryTree().then(response => {
        this.goodsCategoryTreeOptions = response.data
      })
    },
    //获取机构
    getDeptTreeData () {
      getDeptTree().then(response => {
        this.shopTreeOptions = response.data
      })
    },
    //点击一键展开的事件
    getExpand (tree) {
      if (tree === 'shopTree') {
        this.isShopExpand = !this.isShopExpand
      } else {
        this.isGoodsCategoryExpand = !this.isGoodsCategoryExpand
      }
      this.buildData(tree)
    },
    //遍历树的所有子节点，展开的时候给子节点展开状态赋值true，折叠时候赋值false
    buildData (tree) {
      if (tree == 'shopTree') {
        for (
          let i = 0;
          i < this.$refs.shopTree.store._getAllNodes().length;
          i++
        ) {
          this.$refs.shopTree.store._getAllNodes()[i].expanded =
            this.isShopExpand
        }
      } else {
        for (
          let i = 0;
          i < this.$refs.categoryTree.store._getAllNodes().length;
          i++
        ) {
          this.$refs.categoryTree.store._getAllNodes()[i].expanded =
            this.isGoodsCategoryExpand
        }
      }
    },
    //下拉树结构数据转换
    normalizer (node) {
      return {
        id: node.classId,
        label: node.label,
        children: node.children
      }
    },

    // 筛选节点
    filterNodeShop (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 筛选节点
    filterNodeGoodsCategory (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  width: 100%;
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .btnBox {
    width: 100%;
    text-align: right;
  }
  //左边tree结构
  .leftTree {
    width: 500px;
    margin-right: 6px;
    margin-bottom: 0;
    .leftTreeShop {
      .treeShop {
        border: 1px solid #dfe6ec;
      }
      padding: 10px 10px 10px 10px;
    }
  }
  .rightBox {
    width: calc(100% - 506px);
    margin-bottom: 0;
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
.searchTree {
  width: 220px;
}
::v-deep ::-webkit-scrollbar-track {
  background: transparent;
}
//卡片默认边距0
::v-deep .el-card__body {
  padding: 0;
  margin: 0;
}
</style>
