<template>
  <shopInfo />
</template>

<script>
import shopInfo from './components'
export default {
  name: 'baseInfo',
  components: { shopInfo }
}
</script>
