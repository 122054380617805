var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
      attrs: { id: "wrap" },
    },
    [
      _c(
        "div",
        { staticClass: "btnBox marB10", attrs: { id: "search-card" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.saveAllData },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-refresh-right", size: "mini" },
              on: { click: _vm.refresh },
            },
            [_vm._v("刷新")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "x-x" },
        [
          _c("el-card", { staticClass: "leftTree" }, [
            _c("div", { staticClass: "leftTreeShop" }, [
              _c(
                "div",
                { staticClass: "treeShop x-x" },
                [
                  _c(
                    "el-scrollbar",
                    {
                      style: {
                        height: _vm.tableHeight + 35 + "px",
                        borderRight: "1px solid #dfe6ec",
                        width: "50%",
                        padding: "10px 0 0 10px",
                      },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "left-title marB10" }, [
                          _c("span", [_vm._v("门店")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "y-start" },
                          [
                            _c("el-input", {
                              staticClass: "searchTree",
                              staticStyle: { "margin-bottom": "20px" },
                              attrs: {
                                placeholder: "请输入门店名称",
                                clearable: "",
                                size: "mini",
                                "prefix-icon": "el-icon-search",
                              },
                              model: {
                                value: _vm.shopName,
                                callback: function ($$v) {
                                  _vm.shopName = $$v
                                },
                                expression: "shopName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {},
                          [
                            _c("el-tree", {
                              ref: "shopTree",
                              attrs: {
                                "node-key": "id",
                                data: _vm.shopTreeOptions,
                                "filter-node-method": _vm.filterNodeShop,
                                "default-expand-all": _vm.isShopExpand,
                                "show-checkbox": "",
                                "check-on-click-node": "",
                              },
                              on: {
                                check: function ($event) {
                                  return _vm.checkBoxFn("shopTree")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-scrollbar",
                    {
                      style: {
                        height: _vm.tableHeight + 35 + "px",
                        width: "50%",
                        padding: "10px 0 0 10px",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "left-title marB10" }, [
                            _c("span", [_vm._v("商品类别")]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "y-start" },
                            [
                              _c("el-input", {
                                staticClass: "searchTree",
                                staticStyle: { "margin-bottom": "20px" },
                                attrs: {
                                  placeholder: "请输入商品类别名称",
                                  clearable: "",
                                  size: "mini",
                                  "prefix-icon": "el-icon-search",
                                },
                                model: {
                                  value: _vm.goodsCategoryName,
                                  callback: function ($$v) {
                                    _vm.goodsCategoryName = $$v
                                  },
                                  expression: "goodsCategoryName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("el-tree", {
                            ref: "categoryTree",
                            attrs: {
                              "node-key": "id",
                              data: _vm.goodsCategoryTreeOptions,
                              "filter-node-method": _vm.filterNodeGoodsCategory,
                              "default-expand-all": _vm.isGoodsCategoryExpand,
                              "show-checkbox": "",
                              "check-on-click-node": "",
                            },
                            on: {
                              check: function ($event) {
                                return _vm.checkBoxFn("categoryTree")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "cardTitleCom",
            {
              staticClass: "rightBox",
              attrs: {
                cardTitle: "商品信息",
                tooltipText: "只有进场的商品才能上架",
              },
              scopedSlots: _vm._u([
                {
                  key: "rightCardTitle",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "fontColorF00 fontS12" }, [
                        _vm._v(" 此处数据量较大，预计修改成功5分钟后生效。 "),
                      ]),
                      _c("selectAllFilter", {
                        attrs: { filters: _vm.filters },
                        model: {
                          value: _vm.queryParams,
                          callback: function ($$v) {
                            _vm.queryParams = $$v
                          },
                          expression: "queryParams",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "marL10 marR10",
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.getList },
                        },
                        [_vm._v("查询")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContent" },
                  [
                    _c(
                      "el-table",
                      {
                        ref: "multipleTable",
                        attrs: {
                          data: _vm.tableData,
                          "max-height": _vm.tableHeight,
                          height: _vm.tableHeight,
                          border: "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "序号",
                            width: "80",
                            align: "center",
                            type: "index",
                          },
                        }),
                        _c("el-table-column", {
                          key: "goodsNo",
                          attrs: {
                            label: "商品编码",
                            align: "center",
                            prop: "goodsNo",
                            "show-overflow-tooltip": true,
                            sortable: "",
                            "min-width": "160",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品名称",
                            align: "center",
                            prop: "goodsName",
                            "show-overflow-tooltip": true,
                            "min-width": "160",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品条码",
                            align: "center",
                            prop: "barcode",
                            "show-overflow-tooltip": true,
                            "min-width": "160",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "单位",
                            align: "center",
                            prop: "unitName",
                            "show-overflow-tooltip": true,
                            "min-width": "160",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "规格",
                            align: "center",
                            prop: "goodsSpec",
                            "show-overflow-tooltip": true,
                            "min-width": "160",
                          },
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "行全选操作",
                              align: "center",
                              width: "200",
                            },
                          },
                          [
                            _c(
                              "el-table-column",
                              {
                                attrs: { align: "center", width: "100" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              indeterminate:
                                                scope.row
                                                  .isIndeterminateIsCanUse,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeIsCanUseLine(
                                                  scope.row.isCanUse,
                                                  scope.row
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.isCanUse,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "isCanUse",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.isCanUse",
                                            },
                                          },
                                          [_vm._v("进场")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "div",
                                    { staticClass: "x-fc" },
                                    [
                                      _c("span", { staticClass: "marR10" }, [
                                        _vm._v("门店进场"),
                                      ]),
                                      _c("el-checkbox", {
                                        attrs: {
                                          indeterminate:
                                            _vm.isIndeterminateIsCanUseColumn,
                                          disabled: _vm.tableData.length == 0,
                                        },
                                        on: {
                                          change: _vm.changeIsCanUseColumn,
                                        },
                                        model: {
                                          value: _vm.isCanUseColumn,
                                          callback: function ($$v) {
                                            _vm.isCanUseColumn = $$v
                                          },
                                          expression: "isCanUseColumn",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-table-column",
                              {
                                attrs: { align: "center", width: "100" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              disabled:
                                                !scope.row.isCanUse ||
                                                !scope.row.isPosVisibleRearEnd,
                                              indeterminate:
                                                scope.row
                                                  .isIndeterminateIsPosVisible,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeIsPosVisibleLine(
                                                  scope.row.isPosVisible,
                                                  scope.row
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.isPosVisible,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "isPosVisible",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.isPosVisible",
                                            },
                                          },
                                          [_vm._v("上架")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "div",
                                    { staticClass: "x-fc" },
                                    [
                                      _c("span", { staticClass: "marR10" }, [
                                        _vm._v("零售上架"),
                                      ]),
                                      _c("el-checkbox", {
                                        attrs: {
                                          indeterminate:
                                            _vm.isIndeterminateIsPosVisibleColumn,
                                          disabled:
                                            !_vm.isCanUseColumn ||
                                            _vm.isIndeterminateIsCanUseColumn ||
                                            _vm.tableData.length == 0,
                                        },
                                        on: {
                                          change: _vm.changeIsPosVisibleColumn,
                                        },
                                        model: {
                                          value: _vm.isPosVisibleColumn,
                                          callback: function ($$v) {
                                            _vm.isPosVisibleColumn = $$v
                                          },
                                          expression: "isPosVisibleColumn",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _vm._l(_vm.shops, function (item, index) {
                          return _c(
                            "el-table-column",
                            {
                              key:
                                "" +
                                item.id +
                                item.isCanUse +
                                item.isPosVisible +
                                item.isIndeterminateIsCanUse +
                                item.isIndeterminateIsPosVisible,
                              attrs: {
                                label: item.label,
                                align: "center",
                                width: "300",
                              },
                            },
                            [
                              _c(
                                "el-table-column",
                                {
                                  attrs: { align: "center", width: "100" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-checkbox", {
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeIsCanUseItem(
                                                    index,
                                                    scope.row,
                                                    scope.row.shopGoodsReps[
                                                      index
                                                    ].isCanUse
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  scope.row.shopGoodsReps[index]
                                                    .isCanUse,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row.shopGoodsReps[
                                                      index
                                                    ],
                                                    "isCanUse",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.shopGoodsReps[index].isCanUse",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("template", { slot: "header" }, [
                                    _c(
                                      "div",
                                      { staticClass: "x-fc" },
                                      [
                                        _c("span", { staticClass: "marR10" }, [
                                          _vm._v("门店进场"),
                                        ]),
                                        _c("el-checkbox", {
                                          attrs: {
                                            indeterminate:
                                              item.isIndeterminateIsCanUse,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeIsCanUseSelect(
                                                item.id,
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.isCanUse,
                                            callback: function ($$v) {
                                              _vm.$set(item, "isCanUse", $$v)
                                            },
                                            expression: "item.isCanUse",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                2
                              ),
                              _c(
                                "el-table-column",
                                {
                                  attrs: { align: "center", width: "100" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-checkbox", {
                                              attrs: {
                                                disabled:
                                                  !scope.row.shopGoodsReps[
                                                    index
                                                  ].isCanUse ||
                                                  !scope.row
                                                    .isPosVisibleRearEnd,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeIsPosVisibleItem(
                                                    index,
                                                    scope.row
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  scope.row.shopGoodsReps[index]
                                                    .isPosVisible,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row.shopGoodsReps[
                                                      index
                                                    ],
                                                    "isPosVisible",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.shopGoodsReps[index].isPosVisible",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("template", { slot: "header" }, [
                                    _c(
                                      "div",
                                      { staticClass: "x-fc" },
                                      [
                                        _c("span", { staticClass: "marR10" }, [
                                          _vm._v("零售上架"),
                                        ]),
                                        _c("el-checkbox", {
                                          attrs: {
                                            indeterminate:
                                              item.isIndeterminateIsPosVisible,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeIsPosVisibleSelect(
                                                item.id,
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.isPosVisible,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "isPosVisible",
                                                $$v
                                              )
                                            },
                                            expression: "item.isPosVisible",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                2
                              ),
                              _c("el-table-column", {
                                attrs: {
                                  label: "门店售价",
                                  align: "center",
                                  width: "100",
                                  prop: "salePrice",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.shopGoodsReps[index]
                                                  .shopPrice
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      [
                        _c("bottomPage", {
                          attrs: {
                            getList: _vm.getList,
                            isSelectAll: false,
                            totalCom: _vm.total,
                          },
                          model: {
                            value: _vm.queryParams,
                            callback: function ($$v) {
                              _vm.queryParams = $$v
                            },
                            expression: "queryParams",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }