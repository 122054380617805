<template>
  <div class="wrap" id="wrap">
    <div class="tableBtn">
      <el-button type="primary" size="mini" @click="getTableImg">生成桌台码</el-button>
    </div>
    <div class="x-f">
      <!-- 左边树装选择 -->
      <categoryTreeCard style="margin-right: 0" categoryTreeTitle="门店" @treeNodeId="getTreeNodeId"
        :boxHeight="boxHeight" :boxInternalHeight="boxInternalHeight" :categoryTreeOptions="shopList"
        :defaultProps="defaultProps" />
      <div class="right">
        <!-- 关系情况 -->
        <div class="content" v-loading="availableLoading">
          <cardTitleCom cardTitle="楼层" class="cardDetail">
            <template slot="cardContent">
              <div class="tableContent">
                <el-form ref="form" :rules="rules" :model="formDetail">
                  <el-table tooltip-effect="dark" :data="tableDataDetail" border v-loading="loadingDetail"
                    @row-click="handleRowClick" @cell-mouse-enter="
                      row => cellMouseEnter(row, 'tableDataDetail')
                    " @cell-mouse-leave="cellMouseLeave('tableDataDetail')" :max-height="tableHeight"
                    :height="tableHeight">
                    <el-table-column width="55" align="center">
                      <template v-slot="scope">
                        <el-radio v-model="scope.row.selected" :label="true">{{
                          ''
                        }}</el-radio>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" :label="tableCellLabel" width="80">
                      <template v-slot="scope">
                        <i v-if="scope.row.hoverRow" @click.stop="rowDetail('push', scope.$index)"
                          class="el-icon-circle-plus operatePush"></i>
                        <i v-if="scope.row.hoverRow" @click.stop="
                            rowDetail('del', scope.$index, scope.row.floorId)
                          " class="el-icon-remove operateDel"></i>
                        <div v-if="!scope.row.hoverRow">
                          {{ scope.$index + 1 }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="楼层编号" align="center" prop="floorNo" min-width="120">
                      <template slot-scope="scope">
                        <!-- <el-form-item> -->
                        <el-input size="mini" maxlength="20" v-model="scope.row.floorNo" @blur="blurGroup(scope.$index)"
                          @focus="handleRowClick(scope.row)" />
                        <!-- </el-form-item> -->
                      </template>
                    </el-table-column>
                    <el-table-column label="楼层名称" align="center" prop="floorName" min-width="150">
                      <template slot-scope="scope">
                        <!-- <el-form-item> -->
                        <el-input size="mini" maxlength="40" v-model="scope.row.floorName"
                          @blur="blurGroup(scope.$index)" @focus="handleRowClick(scope.row)" />
                        <!-- </el-form-item> -->
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form>
              </div>
            </template>
          </cardTitleCom>
          <cardTitleCom :cardTitle="selectGroupName" class="cardAvailable">
            <template slot="cardContent">
              <div class="tableContent">
                <el-table tooltip-effect="dark" :data="tableDataAvailable" border @cell-mouse-enter="
                    row => cellMouseEnter(row, 'tableDataAvailable')
                  " @cell-mouse-leave="cellMouseLeave('tableDataAvailable')" :max-height="tableHeight"
                  :height="tableHeight" v-loading="loadingTableCode">
                  <el-table-column align="center" :label="tableCellLabel" width="80">
                    <template v-slot="scope">
                      <i v-if="scope.row.hoverRow" @click.stop="rowAvailable('push', scope.$index)"
                        class="el-icon-circle-plus operatePush"></i>
                      <i v-if="scope.row.hoverRow" @click.stop="
                          rowAvailable('del', scope.$index, scope.row.tableId)
                        " class="el-icon-remove operateDel"></i>
                      <div v-if="!scope.row.hoverRow">
                        {{ scope.$index + 1 }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="桌台号" align="center" prop="tableNo" min-width="150">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.tableNo" size="mini" @blur="blurTableCode(scope.row)" />
                    </template>
                  </el-table-column>
                  <el-table-column label="桌台名称" align="center" min-width="150" prop="tableName">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.tableName" size="mini" @blur="blurTableCode(scope.row)" />
                    </template>
                  </el-table-column>
                  <el-table-column label="备注" align="center" prop="remark" min-width="100">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.remark" size="mini" @blur="blurTableCode(scope.row)" />
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </template>
          </cardTitleCom>
        </div>
      </div>
    </div>
    <c-dialog :title="loderName + '桌台码'" :width="1000" :showDialog.sync="dialogFormVisible">
      <template #content>
        <el-scrollbar style="height: 460px; margin-left: 10px">
          <div class="x-w">
            <div v-for="(item, index) in imgList" :key="index" class="urlImg y-c">
              <el-image style="width: 150px; height: 150px" :src="item.qrCodeUrl"
                :preview-src-list="[item.qrCodeUrl]"></el-image>
              <div style="margin-top: 6px" class="x-x">
                <div class="example" style="width: 110px; text-align: center">
                  {{ item.tableName }}
                </div>
                <div class="annotateBlue cursorP" @click="allDownload('one', item)">
                  下载
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </template>
      <template #buttonList>
        <el-button type="primary" @click="allDownload('many')">全部下载</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import CDialog from '@/components/dialogTemplate/dialog'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import bottomPage from '@/views/components/bottomPage' //底部分页
import {
  floorListAPI, //列表
  floorSaveAPI,
  floorUpdateAPI,
  floorRemoveAPI,
  saveFloorTableAPI,
  updateFloorTableAPI,
  deleteTableAPI,
  getTableCodeAPI
} from '@/api/O2OThird/orderMealWxa/floorTable'
import {
  shopListAPI //列表
} from '@/api/O2OThird/orderMealWxa/shop'
import categoryTreeCard from '@/views/components/categoryTreeCard' //选择类别
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import axios from 'axios'
export default {
  name: 'produceGroup',
  components: { bottomPage, cardTitleCom, categoryTreeCard, CDialog },
  data() {
    return {
      loderName: '', //文件名
      imgList: [], //桌台码图
      dialogFormVisible: false, //生成桌台
      shopList: [],
      defaultProps: {
        id: 'shopId',
        label: 'shopName'
      },
      tableCellLabel: '序号',
      selectGroupName: '楼层', //当前选中的生产组的名称
      loadingDetail: false, //生产组表格的加载
      availableLoading: false, //商品表格的加载
      loadingTableCode: false, //桌台码
      formDetail: {}, //生产组信息
      formAvailable: {}, //生产组商品信息
      rules: {
        floorNo: [
          { required: true, message: '楼层编号不能为空', trigger: 'blur' }
        ],
        floorName: [
          { required: true, message: '楼层名称不能为空', trigger: 'blur' }
        ]
      }, //校验表格
      tableDataDetail: [
        {
          lineId: Date.now()
        }
      ], //原料明细表格数据
      // 查询参数
      queryParams: {},
      tableDataDetailContrast: [], //对比数据
      tableDataAvailable: [], //可用物料表格数据
      disabled: false, //禁用状态
      vivwH: 0 //页面高度
    }
  },
  computed: {
    tableHeight() {
      return this.vivwH - 70 - 28
    },
    boxHeight() {
      return this.vivwH - 10 - 28
    },
    boxInternalHeight() {
      return this.vivwH - 38 - 28
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  async created() {
    const res = await shopListAPI({ status: 0, pageNum: 1, pageSize: 99999 })
    this.shopList = res.rows
  },
  methods: {
    //下载
    allDownload(typeName, row) {
      if (typeName === 'one') {
        this.downloadSingleImage(row.qrCodeUrl, row.tableName)
      } else if (typeName === 'many') {
        this.batchQrCodeZip()
      }
    },
    downloadSingleImage(imageUrl, fileName) {
      let image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = imageUrl
      image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob(blob => {
          let url = URL.createObjectURL(blob)
          this.download(url, fileName)
          URL.revokeObjectURL(url)
        })
      }
    },
    // 批量下载图片并生成文件夹
    batchQrCodeZip() {
      let that = this
      let zip = new JSZip()
      const promises = []

      for (const item of this.imgList) {
        const promise = axios({
          method: 'get',
          url: item.qrCodeUrl,
          responseType: 'blob'
        })
          .then(response => {
            zip
              .folder(this.loderName)
              .file(item.tableName + '.png', response.data, { binary: true })
          })
          .catch(error => {
            console.error('Error fetching file:', error)
          })
        promises.push(promise)
      }

      Promise.all(promises)
        .then(() => {
          zip.generateAsync({ type: 'blob' }).then(content => {
            saveAs(content, `${this.loderName}桌台码.zip`)
            that.$message({ type: 'success', message: '下载成功!' })
          })
        })
        .catch(error => {
          console.error('Error exporting files:', error)
          that.$message.error('下载失败!')
        })
    },
    download(href, name) {
      let eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      eleLink.click()
      eleLink.remove()
    },
    //弹窗
    async getTableImg() {
      if (this.formDetail.floorId) {
        this.loderName =
          (this?.shopList?.find(v => v.shopId == this.queryParams.shopId)
            ?.shopName || '') + this?.formDetail?.floorName
        this.dialogFormVisible = true
        const res = await getTableCodeAPI({ floorId: this.formDetail.floorId })
        this.imgList = res?.data?.tableQrCodeItems || []
        console.log('r', res)
      } else {
        this.$message.error('请先创建楼层和桌台码')
      }
    },
    //点击类别树
    async getTreeNodeId(value) {
      this.queryParams.shopId = value
      await this.getList()
    },
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row, table) {
      let eleIndex = this[table].indexOf(row)
      for (let index = 0; index < this[table].length; index++) {
        const element = this[table][index]
        if (eleIndex == index) {
          element['hoverRow'] = true
        } else {
          element['hoverRow'] = false
        }
      }
      this.tableCellLabel = '操作'
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave(table) {
      // 移除hover的事件
      for (let index = 0; index < this[table].length; index++) {
        const element = this[table][index]
        element['hoverRow'] = false
      }
      this.tableCellLabel = '序号'
    },
    //点餐楼层输入框失焦
    async blurGroup(index) {
      try {
        this.availableLoading = true
        //判断编号、名称是否填写
        if (this.formDetail.floorNo && this.formDetail.floorName) {
          if (this.formDetail.floorId) {
            //判断生产组是不是修改
            if (
              JSON.stringify(this.tableDataDetailContrast[index]) !=
              JSON.stringify(this.formDetail)
            ) {
              await this.updateGroup(this.queryParams.shopId)
            }
          } else {
            //新增生产组
            await this.addGroup(this.queryParams.shopId)
          }
        }
        this.availableLoading = false
      } catch (error) {
        this.availableLoading = false
      }
    },
    //点击点餐楼层当前行
    async handleRowClick(row) {
      this.tableDataDetail.forEach(item => {
        if (
          row.floorId ? item.floorId == row.floorId : item.lineId == row.lineId
        ) {
          this.$set(item, 'selected', true)
        } else {
          this.$set(item, 'selected', false)
        }
      })
      this.formDetail = row // 获取当前行的信息
      if (this.formDetail.floorName) {
        this.selectGroupName = this.formDetail.floorName
      } else {
        this.selectGroupName = '楼层'
      }

      if (this.formDetail.floorId) {
        await this.getGroupGoodsList()
      } else {
        this.tableDataAvailable = [{}]
      }
    },
    //桌台码输入框失焦
    async blurTableCode(row) {
      try {
        this.loadingTableCode = true
        //判断编号、名称是否填写
        if (row.tableNo && row.tableName) {
          if (row.tableId) {
            //判断是不是修改
            await updateFloorTableAPI({
              tableId: row.tableId,
              tableNo: row.tableNo,
              tableName: row.tableName,
              remark: row.remark
            })
            this.$message.success('修改桌台码成功！')
          } else {
            //新增
            const { data } = await saveFloorTableAPI({
              floorId: this.formDetail.floorId,
              tableNo: row.tableNo,
              tableName: row.tableName,
              remark: row.remark
            })
            this.$set(row, 'tableId', data.tableId)
            this.$message.success('新增桌台码成功！')
          }
        }
      } finally {
        const res = await floorListAPI(this.queryParams)
        this.tableDataDetail = res.data || []
        this.loadingTableCode = false
      }
    },
    //点餐楼层信息列表
    async getList() {
      this.loadingDetail = true
      const res = await floorListAPI(this.queryParams)
      this.tableDataDetail = res.data || []
      if (this.tableDataDetail.length === 0) {
        this.tableDataDetail = [{ lineId: Date.now() }]
      }
      this.handleRowClick(this.tableDataDetail[0])
      //对比数据
      this.tableDataDetailContrast = JSON.parse(
        JSON.stringify(this.tableDataDetail)
      )
      if (this.formDetail.floorId && this.tableDataDetail?.length > 0) {
        this.tableDataDetail.forEach(item => {
          if (item.floorId == this.formDetail.floorId) {
            this.$set(item, 'selected', true)
          } else {
            this.$set(item, 'selected', false)
          }
        })
      }
      this.loadingDetail = false
    },
    //添加点餐楼层
    async addGroup(shopId) {
      const res = await floorSaveAPI({ ...this.formDetail, shopId })
      this.tableDataDetail.forEach(v => {
        if (v.lineId == this.formDetail.lineId) {
          this.formDetail.floorId = res?.data?.floorId
          this.$set(v, 'selected', true)
          this.$set(v, 'shopId', shopId)
          this.$set(v, 'floorId', res?.data?.floorId)
          this.tableDataAvailable = [{}]
        } else {
          this.$set(v, 'selected', false)
        }
      })

      this.$message({
        type: 'success',
        message: '新增楼层成功!'
      })
      // this.getList()
    },
    //删除
    async removeGroup(floorId) {
      await floorRemoveAPI([floorId])
      this.$message({
        type: 'success',
        message: '删除楼层成功!'
      })
      this.getList()
    },
    //修改
    async updateGroup(shopId) {
      await floorUpdateAPI({ ...this.formDetail, shopId })
      this.tableDataDetail.forEach(item => {
        if (
          this.formDetail.floorId
            ? item.floorId == this.formDetail.floorId
            : item.lineId == this.formDetail.lineId
        ) {
          this.$set(item, 'selected', true)
        } else {
          this.$set(item, 'selected', false)
        }
      })
      this.$message({
        type: 'success',
        message: '修改楼层成功!'
      })
      // this.getList()
    },
    //商品信息列表
    async getGroupGoodsList() {
      //商品表格加载
      this.loadingTableCode = true
      try {
        this.tableDataAvailable = this.formDetail.floorTableItems || [{}]
        if (this.tableDataAvailable.length === 0) {
          this.tableDataAvailable = [{}]
        }
      } catch (error) {
        if (this.tableDataAvailable.length === 0) {
          this.tableDataAvailable = [{}]
        }
      }
      //商品表格加载
      this.loadingTableCode = false
    },
    //删除商品
    async removeTable(tableId) {
      await deleteTableAPI([tableId])
      this.$message({
        type: 'success',
        message: '删除桌台码成功!'
      })
      this.getGroupGoodsList()
    },
    //原料明细表格当中增加/减少一行方法
    rowDetail(name, index, floorId) {
      if (name === 'push') {
        this.tableDataDetail.splice(index + 1, 0, { lineId: Date.now() })
        // this.selectGroupName = '楼层'
      } else {
        if (
          this.tableDataDetail.length <= 1 &&
          !this.tableDataDetail[0].floorId
        )
          return
        this.$confirm('此操作将永久删除该楼层, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            if (floorId) {
              this.removeGroup(floorId)
              this.tableDataAvailable = []
            } else {
              this.tableDataDetail.splice(index, 1)
            }
          })
          .catch()
      }
    },
    //可用物料表格当中增加/减少一行方法
    rowAvailable(name, index, tableId) {
      if (name === 'push') {
        this.tableDataAvailable.splice(index + 1, 0, {})
      } else {
        if (
          this.tableDataAvailable.length <= 1 &&
          !this.tableDataAvailable[0].tableId
        )
          return
        this.$confirm('此操作将永久删除该桌台码, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            console.log('tableId', tableId);
            if (tableId) {
              this.removeTable(tableId)
              this.tableDataAvailable.splice(index, 1)
            } else {
              this.tableDataAvailable.splice(index, 1)
            }
          })
          .catch()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// 最大盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .tableBtn {
    text-align: right;
    margin: 0 10px 6px 0;
  }
  .right {
    width: calc(100% - 354px); //生产组数组
    //关系情况
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      //左边
      .cardDetail {
        margin-bottom: 0;
        width: 45%;
        margin-right: 6px;
      }
      //商品表格卡片
      .cardAvailable {
        margin-bottom: 0;
        width: 53%;
      }
      .tableContent {
        padding: 6px 10px 10px 10px;
      }
    }
  }
  .urlImg {
    padding: 8px 14px;
    border: 1px solid #eee;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    margin-right: 20px;
    .cursorP:hover {
      color: #5ba9f9;
      opacity: 0.8; /* 悬停时透明度变为80% */
    }
  }
}
</style>
