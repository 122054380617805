<template>
  <!-- 厨打方案 -->
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            v-model="queryParams"
            :getList="getList"
            @isShowHighCom="getIsShowHigh"
            :dropDownList="dropDownList"
            :isBillDate="false"
            :isSearchInput="true"
            isSearchInputTitle="输入方案编号、名称"
          />
          <div v-show="showHigh" class="marT10">
            <span class="fS14MR10 marL15">是否启用：</span>
            <el-select
              size="mini"
              v-model="queryParams.isStop"
              placeholder="请选择是否启用"
              clearable
            >
              <el-option
                v-for="item in isStopData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <!-- 表格商品信息卡片 -->
    <cardTitleCom cardTitle="商品打印方案" class="headGoodsTable">
      <template slot="cardContent">
        <!-- 商品信息按钮和表格整体盒子 -->
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton
            :getList="getList"
            :multiple="multiple"
            :isCopyBtn="true"
            :isEnableBtn="true"
            @handleEnable="handleEnable"
            @handleCopy="handleCopy"
            @handleAdd="handleAdd"
            @handleDelete="handleDelete"
          />
          <div>
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange"
              border
              v-loading="loadingTable"
              @row-click="handleRowClick"
              :max-height="tableHeight"
              :height="tableHeight"
            >
              <el-table-column type="selection" width="50" align="center" />
              <el-table-column type="index" width="50" label="序号" />
              <el-table-column
                label="方案编号"
                align="center"
                key="billNo"
                prop="printCaseNo"
                width="200"
              >
                <template slot-scope="scope">
                  <el-link
                    type="primary"
                    @click="handleDetail(scope.row)"
                    target="_blank"
                  >
                    {{ scope.row.printCaseNo }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column
                label="方案名称"
                align="center"
                key="printCaseName"
                prop="printCaseName"
                :show-overflow-tooltip="true"
              />

              <el-table-column
                label="启用状态"
                align="center"
                key="isStop"
                prop="isStop"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.isStop ? '禁用' : '启用' }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="备注"
                align="center"
                key="remark"
                prop="remark"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                label="修改人"
                align="center"
                key="updateBy"
                prop="updateBy"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                label="修改时间"
                align="center"
                key="updateTime"
                prop="updateTime"
                :show-overflow-tooltip="true"
                width="180px"
              />
              <el-table-column
                label="创建人"
                align="center"
                key="createBy"
                prop="createBy"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                label="创建时间"
                align="center"
                key="createTime"
                prop="createTime"
                :show-overflow-tooltip="true"
                width="180px"
              />
            </el-table>
            <bottomPage
              v-model="queryParams"
              :getList="getList"
              :idsCom="ids"
              :totalCom="total"
              @selectAllCom="selectAll"
            />
          </div>
        </div>
      </template>
    </cardTitleCom>
    <!-- <el-card class="box-card">
      <div class="header">
        <div class="btn x-b">
          <div>
            <el-button
              icon="el-icon-plus"
              plain
              size="mini"
              class="blue-btn"
              @click="handleAdd"
              >新增</el-button
            >

            <el-dropdown
              class="marR10 marL10"
              split-button
              type="warning"
              icon="el-icon-circle-check"
              :disabled="multiple"
              @click="handleEnable(false)"
              @command="handleEnable(true)"
              size="mini"
            >
              启用
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="禁用">禁用</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              class="acidBlue-btn"
              icon="el-icon-picture-outline"
              plain
              size="mini"
              @click="handleCopy"
              >复制</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              :disabled="multiple"
              @click="handleDelete"
              size="mini"
              >删除
            </el-button>
          </div>
          <div>
            <el-button
              class="white-btn"
              icon="el-icon-picture-outline"
              plain
              size="mini"
              @click="getList"
              >刷新</el-button
            >
          </div>
        </div>
      </div>

      <div class="table">
     
        <el-table
          v-loading="loading"
          ref="multipleTable"
          @row-click="handleRowClick"
          :data="tableData"
          @selection-change="handleSelectionChange"
          border
          height="605"
        >
          <el-table-column
            type="selection"
            width="50"
            align="center"
            fixed="left"
          />
          <el-table-column
            type="index"
            width="50"
            align="center"
            label="序号"
            fixed="left"
          />
         
        </el-table>
       
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </div>
    </el-card> -->
  </div>
</template>

<script>
import {
  cookPrintDeleteAPI,
  cookPrintIsStopAPI,
  cookPrintListAPI,
  cookPrintCopyAPI
} from '@/api/shop/base/cookPrintCase' //厨打商品
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import bottomPage from '@/views/components/bottomPage' //底部分页
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
export default {
  name: 'cookPrintCase',
  dicts: ['bill_status'], //活动方案状态
  components: { cardTitleCom, bottomPage, seniorSearch, operatingButton },
  data () {
    return {
      loadingTable: false,
      showHigh: false, //是否显示高级搜索
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'printCaseNos', name: '方案编号' },
        { parameter: 'printCaseNames', name: '方案名称' }
      ],
      //遮罩层
      loading: false,
      billStatusStopId: [], //选中数组的禁用的状态
      billStatusIsStopId: [], //选中数组的启用的状态
      //是否启用数据
      isStopData: [
        { label: '启用', value: false },
        { label: '禁用', value: true }
      ],
      ids: [], //选中的数据id
      selectionData: [], //选中的数据
      //活动方案表格数据列表
      tableData: [],
      //活动方案总数
      total: 0,
      //选中的活动方案Id
      billIds: [],
      multiple: true, // 非单个禁用
      //搜索数据
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        query: undefined, //促销方案编号/促销方案名称
        executionStatus: undefined, //执行状态
        isStop: undefined //是否启用
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  created () {
    this.getList()
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 140
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //点击全选所有数据
    async selectAll (value) {
      if (value) {
        this.loadingTable = true
        const res = await cookPrintListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    async getList () {
      this.loadingTable = true
      const { rows, total } = await cookPrintListAPI(this.queryParams)

      this.tableData = rows
      // console.log("tableData ", this.tableData);
      this.total = total
      this.loadingTable = false
    },

    //启用停用
    async handleEnable (value) {
      if (!value) {
        if (this.billStatusIsStopNoId.length === 0) {
          this.$message.error('只能启用：已禁用的状态！')
          return
        }
        // const obj = {
        //   printCaseIds: this.billStatusIsStopNoId
        // }
        await cookPrintIsStopAPI(this.billStatusIsStopNoId)
        this.$message({
          message: `启用成功`,
          type: 'success'
        })
      } else {
        if (this.billStatusIsStopId.length === 0) {
          this.$message.error('只能停用：已启用的状态')
          return
        }
        // const obj = {
        //   printCaseIds: this.billStatusIsStopId
        // }

        await cookPrintIsStopAPI(this.billStatusIsStopId)
        this.$message({
          message: `禁用成功`,
          type: 'success'
        })
      }
      await this.getList()
    },

    //复制事件
    handleCopy () {
      console.log('this.billIds', this.ids)
      if (this.ids.length <= 0)
        return this.$message.warning('请选择要执行的数据')
      cookPrintCopyAPI(this.ids).then(res => {
        this.getList()
        this.$message.success('活动方案复制成功')
      })
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //更新状态事件
    // handleUpdateStatus(status) {
    //   if (this.billIds.length <= 0)
    //     return this.$message.warning("请选择要执行的数据");
    //   updateStatusPromo({
    //     billIds: this.billIds,
    //     billStatus: status,
    //   }).then((res) => {
    //     this.getList();
    //     if (status === "0") this.$message.success("活动方案反审核成功");
    //     if (status === "2") this.$message.success("活动方案审核成功");
    //     if (status === "3") this.$message.success("活动方案删除成功");
    //   });
    // },
    // 多选框选中数据
    handleSelectionChange (selection) {
      console.log('selection', selection)
      this.ids = selection.map(item => item.printCaseId) //单据id
      this.selectionData = selection
      console.log('this.ids', this.ids)
      //筛选出来禁用的单据状态
      this.billStatusIsStopNoId = selection
        .map(item => {
          if (item.isStop) {
            return item.printCaseId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来启用的单据状态
      this.billStatusIsStopId = selection
        .map(item => {
          if (!item.isStop) {
            return item.printCaseId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      this.multiple = !selection.length
    },
    //删除
    async handleDelete () {
      try {
        await this.$confirm(`确定删除所选订单, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })
        if (this.billStatusIsStopNoId.length == 0) {
          this.$message.error('只能删除：已禁用的状态！')
          return
        }
        //发送删除api
        await cookPrintDeleteAPI(this.billStatusIsStopNoId)
        // 弹出提示
        this.$message.success('删除成功')
        //刷新列表
        await this.getList()
      } catch {}
    },

    //新增促销方案事件
    handleAdd () {
      this.$router.push({
        name: 'cookPrintCaseDetail'
      })
    },
    //跳转详情
    handleDetail (row) {
      const printCaseId = row.printCaseId
      this.$router.push({
        name: 'cookPrintCaseDetail',
        query: {
          printCaseId: printCaseId,
          type: 'Update'
        }
      })
    },

    //修改事件 获取id跳转详情页进行修改
    // handleDetail(row) {
    //   const billId = row.billId;
    //   let promoType = row.promoType;
    //   let routerName = "";
    //   if (promoType === "0") routerName = "SpecialPromo";
    //   if (promoType === "1") routerName = "QuantitySpecial";
    //   if (promoType === "2") routerName = "FixedGroup";
    //   if (promoType === "3") routerName = "DynamicGroup";
    //   if (promoType === "4") routerName = "Discount";
    //   if (promoType === "5") routerName = "AmountDiscount";
    //   if (promoType === "6") routerName = "MoneyDiscount";
    //   if (promoType === "7") routerName = "MoneyFullDiscount";
    //   if (promoType === "8") routerName = "FulnessFullGift";
    //   if (promoType === "9") routerName = "RefillFree";
    //   if (promoType === "10") routerName = "GroupDiscounts";
    //   this.$router.push({
    //     name: cookPrintCaseDetail,
    //     query: {
    //       billId: billId, //优惠券id
    //       type: "Update",
    //     },
    //   });
    // },
    //清空搜索框
    reset () {
      this.queryParams = {
        pageNum: 1,
        pageSize: 15,
        query: undefined, //促销方案编号/促销方案名称
        executionStatus: undefined, //执行状态
        isStop: undefined //是否启用
      }
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
//整体盒子

.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  .headGoodsTable {
    height: calc(100% - 94px);
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
</style>
